import React from 'react';
import { useMediaQuery } from 'helpers/PDFFormatter/mediaSize';
import { amtNumStr, amtStr } from 'helpers/StringNumberFunction/numFormatter';

export const LedgerTable1 = (props: any) => {
  const {
    taxRate,
    taxAmt,
    docAmt,
    baseAmt,
    desc,
    glItem,
    materialData,
    taxData,
    customItems = [],
    isEInvoice,
  } = props;
  let Amt = parseFloat(amtNumStr(docAmt));
  // let taxAmt = (Amt * taxRate) / 100;
  // let sum = Amt + taxAmt;

  ////////TO DETECT CHANGE IN SCREEN SIZE///////
  const [width] = useMediaQuery();

  let fontSize;
  if (width < 769) {
    fontSize = 5;
  } else {
    fontSize = 10;
  }

  return (
    <>
      <div style={{ right: 150 }}>
        <table style={{ width: '100%', fontSize, borderCollapse: 'collapse' }}>
          <tbody>
            <tr>
              <th
                style={{
                  width: '25%',
                  verticalAlign: 'top',
                  textAlign: 'left',
                  borderTop: '1px solid black',
                  borderBottom: '1px solid black',
                }}
              >
                Description
              </th>
              {isEInvoice === true && (
                <th style={style.border}>Classification</th>
              )}
              <th style={style.border}>Amount</th>
              <th style={style.border}>Tax Rate</th>
              <th style={style.border}>Tax Amount</th>
              <th style={{ ...style.border, textAlign: 'end' }}>{`${
                !!!glItem ? 'Total Amount' : 'Item Amount'
              }`}</th>
            </tr>

            {customItems?.length > 0 &&
              customItems?.map((v, i) => {
                return (
                  <tr style={{ textAlign: 'center' }} key={i}>
                    <td
                      style={{
                        width: '25%',
                        verticalAlign: 'top',
                        textAlign: 'left',
                      }}
                    >
                      {v?.desc || v?.description || ''}
                    </td>
                    <td>
                      {amtStr(v?.docAmt - v?.taxAmt) ?? amtStr(v?.baseAmt)}
                    </td>
                    <td>{`${v?.taxRate}%`}</td>
                    <td>{amtStr(v?.taxAmt)}</td>
                    <td style={{ textAlign: 'end' }}>{amtStr(v?.docAmt)}</td>
                  </tr>
                );
              })}

            {glItem?.length > 0 &&
              glItem?.map((gl, i) => {
                return (
                  <>
                    <tr style={{ textAlign: 'center' }} key={i}>
                      <td
                        style={{
                          width: '25%',
                          verticalAlign: 'top',
                          textAlign: 'left',
                        }}
                      >
                        {gl?.revenueCategory?.name ??
                          gl?.costItem?.name ??
                          'N/A'}
                      </td>
                      {isEInvoice === true && gl?.classificationCode && (
                        <td>{gl?.classificationCode?.classificationCode}</td>
                      )}
                      <td>{amtStr(gl?.docAmt - gl?.taxAmt)}</td>
                      <td>{`${gl?.taxRate}%`}</td>
                      <td>{amtStr(gl?.taxAmt)}</td>
                      <td style={{ textAlign: 'end', paddingRight: '7px' }}>
                        {amtStr(gl?.docAmt)}
                      </td>
                    </tr>
                    {!!gl?.remarks && <tr key={i}>{`R: ${gl?.remarks}`}</tr>}
                  </>
                );
              })}

            {(glItem?.length === 0 || !glItem) && customItems?.length === 0 && (
              <tr style={{ textAlign: 'center' }}>
                <td
                  style={{
                    width: '25%',
                    verticalAlign: 'top',
                    textAlign: 'left',
                  }}
                >
                  {desc}
                </td>
                <td>{baseAmt}</td>
                <td>{taxRate ? taxRate + '%' : ''}</td>
                <td>{taxAmt}</td>
                <td style={{ textAlign: 'end' }}>{docAmt}</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div style={{ height: '20%', verticalAlign: 'bottom' }}></div>
    </>
  );
};

export default LedgerTable1;

const style = {
  border: {
    borderTop: '1px solid black',
    borderBottom: '1px solid black',
  },
};
